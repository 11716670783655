import React from "react"
import icons from "./icons"
import { ProfileType } from "../../types"

const Tools = ({ tools, heading = "Other Skills" }) => {
  return (
    <>
      <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
        {heading}
      </h5>
      <ul className="flex flex-wrap mt-2">
        {tools.map(tool => {
          const SkillIcon = icons[`${tool.id}`]
          return (
            <li className="relative inline-block mb-1 mr-1 " key={tool.value}>
              <span className="flex items-center inline-block subpixel-antialiased bg-lead px-3 py-1 font-header font-light text-xl md:text-1xl text-lead-text transition-color duration-200 ease-in-out">
                {tool.value}
                {SkillIcon && <SkillIcon className="pl-1" />}
              </span>
            </li>
          )
        })}
      </ul>
    </>
  )
}

Tools.propTypes = {
  tools: ProfileType.tools,
  heading: ProfileType.heading,
}

export default Tools

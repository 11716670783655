import { graphql } from "gatsby"
import React from "react"
import CustomFonts from "../components/custom-fonts/custom-fonts"
import SEO from "../components/seo/seo"
import StructuredData from "../components/structured-data/structured-data"
import App from "../components/app/app"
import ThemeProvider from "../components/theme-provider/theme-provider"
// import "../styles/style.css"

const IndexPage = ({ data }) => {
  const { profile, social } = data

  return (
    <ThemeProvider>
      <div>
        <SEO />
        <StructuredData profile={profile} social={social.nodes} />
        <CustomFonts />
        <App data={data} />
      </div>
    </ThemeProvider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    social: allSocialYaml(filter: { url: { ne: null } }) {
      nodes {
        ...SocialFragment
      }
    }
    history: allWorkHistoryYaml {
      nodes {
        ...WorkHistoryFragment
      }
    }
    projects: allProjectsYaml {
      nodes {
        ...ProjectFragment
      }
    }
  }
`

import React, { useContext, useEffect, useState } from "react"
import Footer from "../footer/footer"
import Header from "../header/header"
import MainContent from "../main-content/main-content"
import Sidebar from "../sidebar/sidebar"
import { ThemeContext } from "../theme-provider/theme-provider"

const App = ({ data }) => {
  const { history, profile, projects, social } = data
  const [isClient, setClient] = useState(false)
  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    // Have to do it because of the hydration issue. ReactDOM has trouble patching text content (e.g. attributes) that differ between the server-rendered content vs. what's now available on the content.
    setClient(true)
  }, [])

  const key = isClient ? `client` : `server`
  return (
    <div className={`theme-${theme}`} key={key}>
      <div
        className={`antialiased bg-back leading-normal font-text text-front transition-color duration-200 ease-in-out`}
      >
        <Header initials={profile.initials} social={social.nodes} />
        <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex flex-wrap pt-4 my-8">
          <Sidebar profile={profile} social={social.nodes} />

          <MainContent
            history={history.nodes}
            profile={profile}
            projects={projects.nodes}
          />
        </div>
        <Footer name={profile.name} />
      </div>
    </div>
  )
}

export default App

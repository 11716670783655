import React from "react"
import About from "../about/about"
// import ContactForm from "../contact-form/contact-form"
import Projects from "../projects/projects"
import Summary from "../summary/summary"
import Tools from "../tools/tools"
import WorkHistory from "../work-history/work-history"

const MainContent = ({ history, projects, profile }) => {
  return (
    <main className="lg:w-2/3 lg:pl-8 xl:pl-12">
      <Summary profile={profile} />

      <div>
        <h2 className="font-header font-semibold text-front text-md uppercase mb-6">
          Skills
        </h2>
        <div className="pb-8">
          {profile.tools && (
            <Tools
              tools={profile.tools}
              heading="Front-end (Languages/Tools)"
            />
          )}
        </div>
        <div className="pb-8">
          {profile.backEndTools && (
            <Tools
              tools={profile.backEndTools}
              heading="Back-end (Languages/Tools)"
            />
          )}
        </div>

        <div className="pb-8">
          {profile.opTools && <Tools tools={profile.opTools} heading="Ops" />}
        </div>

        <div className="pb-8">
          {profile.otherTechSkills && (
            <Tools
              tools={profile.otherTechSkills}
              heading="Other Technical Skills"
            />
          )}
        </div>

        <div className="pb-8">
          {profile.otherSkills && (
            <Tools tools={profile.otherSkills} heading="Soft Skills" />
          )}
        </div>
      </div>

      {profile.about && <About about={profile.about} />}
      <WorkHistory history={history} />
      <Projects projects={projects} />
      {/* <ContactForm email={profile.email} budget={profile.budget} /> */}
    </main>
  )
}

export default MainContent

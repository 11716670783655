import React, { useState, useEffect } from "react"

export const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(undefined)

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme") || "dark"
    setTheme(currentTheme)
  }, [])

  const toggle = () => {
    const newTheme = theme === "light" ? "dark" : "light"
    localStorage.setItem("theme", newTheme)
    setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, toggle }}>
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeProvider

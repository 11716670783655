import { graphql } from "gatsby"
import {
  arrayOf,
  bool,
  number,
  shape,
  string,
  object,
  oneOfType,
} from "prop-types"

export const ProfileType = {
  about: string.isRequired,
  budget: shape({
    currency: string.isRequired,
    default: number.isRequired,
    max: number.isRequired,
    min: number.isRequired,
  }),
  company: string.isRequired,
  email: string.isRequired,
  focus: string.isRequired,
  focus_url: string,
  for_hire: bool.isRequired,
  image: shape({
    childImageSharp: object.isRequired,
    publicURL: string.isRequired,
  }),
  image_dark: shape({
    childImageSharp: object.isRequired,
    publicURL: string.isRequired,
  }),
  initials: string.isRequired,
  location: string.isRequired,
  name: string.isRequired,
  profession: string.isRequired,
  relocation: bool.isRequired,
  skills: arrayOf(string).isRequired,
  tools: arrayOf(oneOfType([string, object])).isRequired,
  heading: string,
}

export const query = graphql`
  fragment ProfileFragment on ProfileYaml {
    about
    company
    email
    focus
    focus_url
    for_hire
    image {
      childImageSharp {
        fixed(width: 144, height: 144, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      publicURL
    }
    image_dark {
      childImageSharp {
        fixed(width: 144, height: 144, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      publicURL
    }
    initials
    location
    name
    profession
    relocation
    skills
    tools {
      id
      value
      isTop
    }
    opTools {
      id
      value
      isTop
    }
    backEndTools {
      id
      value
      isTop
    }
    otherSkills {
      id
      value
      isTop
    }
    otherTechSkills {
      id
      value
      isTop
    }
  }
`

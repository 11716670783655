import React from "react"
import Location from "./location"
import ProfileImage from "./profile-image"
import { arrayOf, shape, ProfileType, SocialType } from "../../types"
import SocialLinks from "../social-links/social-links"

const Sidebar = ({ profile, social }) => {
  return (
    <aside className="w-full lg:w-1/3 lg:border-r border-line lg:px-6 xl:px-12">
      <div className="flex flex-col h-full ">
        <div>
          <h2 className="font-header font-medium text-front text-2xl leading-none mb-4 text_3 leading-tight">
            {profile.profession}
          </h2>
          <h1 className="font-header font-black text-front text-5xl leading-none break-words mb-6">
            {profile.name}
          </h1>
          {profile.image && (
            <ProfileImage image={profile.image} name={profile.name} />
          )}
          <br />
          {profile.location && (
            <Location
              location={profile.location}
              relocation={profile.relocation}
            />
          )}
        </div>

        <div className="pt-8 pb-12 ">
          <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
            Connnect
          </h5>
          <SocialLinks social={social} />
        </div>
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  profile: shape(ProfileType),
  social: arrayOf(shape(SocialType)),
}

export default Sidebar

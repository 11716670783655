import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaVial,
  FaDocker,
  FaDharmachakra,
  FaAws,
  FaJenkins,
  FaGitlab,
  FaGithubAlt,
  FaDiamond,
  FaNodeJs,
  FaGraphQL,
  FaPython,
  FaServer,
  FaSitemap,
  FaStream,
  FaDesktop,
  FaShieldAlt,
  FaAssistiveListeningSystems,
  FaRunning,
  FaBrain,
  FaUserFriends,
  FaFistRaised,
  FaArrowAltCircleUp,
} from "react-icons/fa"

export default {
  Html5: FaHtml5,
  Css3: FaCss3,
  Js: FaJs,
  React: FaReact,
  Vial: FaVial,
  Docker: FaDocker,
  Dharmachakra: FaDharmachakra,
  Aws: FaAws,
  Jenkins: FaJenkins,
  Gitlab: FaGitlab,
  GithubAlt: FaGithubAlt,
  Diamond: FaDiamond,
  NodeJs: FaNodeJs,
  GraphQL: FaGraphQL,
  Python: FaPython,
  Server: FaServer,
  Sitemap: FaSitemap,
  Stream: FaStream,
  Desktop: FaDesktop,
  ShieldAlt: FaShieldAlt,
  AssistiveListeningSystems: FaAssistiveListeningSystems,
  Running: FaRunning,
  Brain: FaBrain,
  UserFriends: FaUserFriends,
  FistRaised: FaFistRaised,
  ArrowAltCircleUp: FaArrowAltCircleUp,
}

import React, { useContext } from "react"
import { useInView } from "react-intersection-observer"
import { ThemeContext } from "../theme-provider/theme-provider"
import { ProfileType } from "../../types"
import { FaSun, FaMoon } from "react-icons/fa"

const Header = ({ initials }) => {
  const [ref, inView, entry = {}] = useInView()
  const { theme, toggle } = useContext(ThemeContext)
  return (
    <>
      <div ref={ref}></div>
      <header
        className={`flex justify-between p-4 lg:px-8 sticky top-0 bg-back z-20 transition-shadow duration-200 ease-in-out transition-color duration-200 ease-in-out ${
          entry.intersectionRatio <= 0 ? "shadow-lg" : ""
        }`}
      >
        <span className="inline-flex w-10 h-10 font-header font-bold text-md justify-center items-center text-center text-front border-2 border-solid border-front rounded-full shadow-md">
          {initials}
        </span>

        {theme && (
          <button
            className="flex w-10 h-10 font-header font-semibold  bg-lead rounded-full text-lead-text justify-center items-center leading-tight  hover:opacity-75 transition-opacity duration-150 shadow-md"
            onClick={toggle}
            aria-label={`Switch to ${
              theme === "light" ? "dark" : "light"
            } mode`}
          >
            {theme === "light" ? (
              <FaMoon className="inline-block h-5 w-5" />
            ) : (
              <FaSun className="inline-block h-6 w-6" />
            )}
          </button>
        )}
      </header>
    </>
  )
}

Header.propTypes = {
  initials: ProfileType.initials,
}

export default Header
